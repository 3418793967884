import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { IJurassicOutpostSettings } from "../types/settings";

interface IProps {
  description?: string;
  keywords?: string[];
  title: string;
  author?: string;
  imagePath?: string;
  slug?: string;
}

interface ISettings {
  jurassicOutpostSettings: Pick<
    IJurassicOutpostSettings,
    "metadataTags" | "metadataDescription"
  >;
}

interface IData {
  allWp: {
    nodes: ISettings[];
  };
}

const SEO = ({
  description,
  keywords,
  title,
  imagePath,
  author,
  slug,
}: IProps) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data: IData) => {
        const metaDescription =
          description ||
          data.allWp.nodes[0].jurassicOutpostSettings.metadataDescription;

        const siteTags = data.allWp.nodes[0].jurassicOutpostSettings.metadataTags.replace(
          /(<([^>]+)>)/gi,
          ""
        );

        return (
          <Helmet
            htmlAttributes={{
              lang: "en",
            }}
            title={title}
            titleTemplate={
              title !== "Your source for Jurassic Park news & info"
                ? `%s | Jurassic Outpost`
                : `Jurassic Outpost | %s`
            }
            meta={[
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: `https://www.jurassicoutpost.com/${slug}`,
              },
              {
                property: `og:image`,
                content: imagePath
                  ? `https://jurassicoutpost.com${imagePath}`
                  : "https://controlroom.jurassicoutpost.com/app/uploads/2019/04/hero-banner-1.jpg",
              },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `google-site-verification`,
                content: "zW4_j-xcfIMuxEGyp3Q8MJgX7IHgZhu6eZRoIWvOppY",
              },
              {
                property: `fb:app_id`,
                content: `449497319180129`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: author || "Jurassic Outpost",
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ].concat({
              name: `keywords`,
              content: `${siteTags}, ${keywords && keywords.length > 0 ? keywords.join(`, `) : "dinos"
                }`,
            })}
          >
            <script
              async
              src="https://platform.twitter.com/widgets.js"
              charSet="utf-8"
            />
            <script
              async
              src="https://platform.instagram.com/en_US/embeds.js"
            />
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9708059968770892"
              crossOrigin="anonymous"
            />

          </Helmet>
        );
      }}
    />
  );
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    allWp {
      nodes {
        jurassicOutpostSettings {
          metadataTags
          metadataDescription
        }
      }
    }
  }
`;
