import { some } from "core-js/fn/array";
import { graphql, Link, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import { random } from "lodash";
import moment from "moment";
import * as React from "react";
import { useCookies } from "react-cookie/es6";
import { presets } from "../lib/presets";
import styled from "../lib/styled";
import { mainTheme } from "../lib/theme";
import { IJurassicOutpostSettings } from "../types/settings";
import Confines from "./Confines";
import SiteSearch from "./Search/SiteSearch";
import SocialIcons from "./SocialIcons";

interface ISettings {
  jurassicOutpostSettings: Pick<
    IJurassicOutpostSettings,
    | "siteLogo"
    | "siteHeaderBanner1Image"
    | "siteHeaderBanner2Image"
    | "siteHeaderBanner3Image"
    | "socialFacebookLink"
    | "socialTwitterLink"
    | "socialInstagramLink"
    | "socialYouTubeLink"
  >;
}

interface IData {
  data: {
    allWp: {
      nodes: ISettings[];
    };
  };
}

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.color.darkThree};
  position: relative;
  height: 250px;
  ${presets.mq.max.phablet} {
    height: 150px;
  }
  width: 100%;
  display: flex;
`;

const LogoPosition = styled(Link)`
  position: absolute;
  left: 50%;
  top: 50%;
  ${presets.mq.max.phablet} {
    padding-top: 40px;
  }
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  img {
    margin: 0;
  }
`;

const TopBar = styled.div`
  display: flex;
  z-index: 1;
  justify-content: space-between;
`;

const Header = ({ data }: IData) => {
  const headerImages =
    [
      data.allWp.nodes[0].jurassicOutpostSettings.siteHeaderBanner1Image,
      data.allWp.nodes[0].jurassicOutpostSettings.siteHeaderBanner2Image,
      data.allWp.nodes[0].jurassicOutpostSettings.siteHeaderBanner3Image,
    ].filter(el => el);
  const [cookies, setCookie] = useCookies(["headerIndex"]);

  const headerIndex = random(0, headerImages.length - 1);

  React.useEffect(() => {
    if (!cookies || !cookies.headerIndex) {
      setCookie("headerIndex", headerIndex, {
        expires: moment().add(1, "day").toDate(),
      });
    }
  }, []);

  const headerImage = headerImages[(cookies && cookies.headerIndex) || headerIndex];

  return (
    <HeaderWrapper>
      {headerImage?.childImageSharp && (
        <GatsbyImage
          alt="Header"
          backgroundColor={mainTheme.color.darkThree}
          image={headerImage.childImageSharp.gatsbyImageData}
          style={{
            zIndex: 0,
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}
      <Confines>
        <TopBar>
          <SiteSearch />
          <LogoPosition to="/">
            <GatsbyImage
              alt="Logo"

              image={
                data.allWp.nodes[0].jurassicOutpostSettings.siteLogo.childImageSharp.gatsbyImageData
              }
              className="logo"
            />
          </LogoPosition>
          <SocialIcons
            facebook={
              data.allWp.nodes[0].jurassicOutpostSettings.socialFacebookLink
            }
            twitter={
              data.allWp.nodes[0].jurassicOutpostSettings.socialTwitterLink
            }
            instagram={
              data.allWp.nodes[0].jurassicOutpostSettings.socialInstagramLink
            }
            youtube={
              data.allWp.nodes[0].jurassicOutpostSettings.socialYouTubeLink
            }
          />
        </TopBar>
      </Confines>
    </HeaderWrapper>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query headerQuery {
        allWp {
          nodes {
            jurassicOutpostSettings {
              siteHeaderBanner1Image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1984)
                }
              }
              siteHeaderBanner2Image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1984)
                }
              }
              siteHeaderBanner3Image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1984)
                }
              }
              siteLogo {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, height: 90)
                }
              }
              socialTwitterLink
              socialFacebookLink
              socialInstagramLink
              socialYouTubeLink
            }
          }
        }
      }
    `}
    render={(data) => <Header data={data} {...props} />}
  />
);
