import React from "react";
import styled from "../lib/styled";
import { PageProps } from "gatsby";

interface AdProps {
  location: PageProps["location"];
}
const Ad: React.FC<AdProps> = (props) => {
  React.useEffect(() => {
    try {
      // @ts-ignore
      window.adsbygoogle = window.adsbygoogle || [];
      // @ts-ignore
      window.adsbygoogle.push({});
    } catch (error) {
      console.error(error);
    }
  }, [props.location.pathname]);

  return (
    <Wrapper key={props.location.pathname}>
      <WidthWrapper>
        <ins
          style={{ display: "block" }}
          className="adsbygoogle"
          data-ad-client="ca-pub-9708059968770892"
          data-ad-slot="5169957358"
          data-ad-format="auto"
          data-full-width-responsive="true"
        />
      </WidthWrapper>
    </Wrapper>
  );
};
const WidthWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  max-height: 150px;
  display: block;
`;

const Wrapper = styled.div`
  padding: 30px;
  margin: 0 20px;
  background: ${(props) => props.theme.color.darkTwo};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Ad;
