import * as React from "react";
import { mainTheme } from "./theme";
import { ITheme } from "./theme-definition";

interface IProvidedAppProps {
  theme: ITheme;
}

interface IAsyncThemeState {
  theme?: ITheme;
  err?: any; // needs to change
}

export const asyncTheme = (
  WrappedComponent: React.SFC<IProvidedAppProps> | React.ComponentClass,
  theme: string | ITheme
) =>
  class extends React.Component<{}, IAsyncThemeState> {
    constructor(props: IProvidedAppProps) {
      super(props);
      this.state = {
        theme: undefined
      };
    }

    public componentDidMount() {
      if (typeof theme === "string") {
        fetch(theme)
          .then(themeJson => themeJson.json())
          .then(themeJson => this.setState({ theme: themeJson }))
          .catch(err => {
            this.setState({ theme: mainTheme });
          });
      } else {
        this.setState({ theme });
      }
    }

    public render() {
      return this.state.theme ? (
        <WrappedComponent theme={this.state.theme} />
      ) : (
        <></>
      );
    }
  };
