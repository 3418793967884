import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "../lib/styled";
import useAxios from "axios-hooks";
import * as _ from "lodash";
import ReactHtmlParser from "react-html-parser";

interface IProps {
  minCharacters: number;
}

interface IEdge {
  node: {
    id: string;
    post_title: string;
    path: string;
  };
}

const SearchComponent: React.SFC<IProps> = (props) => {
  const [query, setQuery] = useState("");

  const [response, fetch] = useAxios(
    {
      url: `https://controlroom.jurassicoutpost.com/wp-json/wp/v2/search`,
      method: "GET",
    },
    { manual: true }
  );

  // Default props
  const minCharacters = props.minCharacters || 3;

  const performSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    if (e.target.value.length >= minCharacters) {
      fetch({
        params: {
          search: e.target.value,
        },
      });
    }
  }, 400);

  return (
    <Container>
      <Input
        id="query"
        name="query"
        aria-label="Enter your search term"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.persist();
          performSearch(e);
        }}
        // onChange={event => setQuery(event.target.value)}
        autoComplete="off"
      />

      {query.length >= minCharacters && (
        <Suggests>
          {response.error && (
            <p>Error fetching search results: {response.error}</p>
          )}
          {response.loading && <p>Loading results...</p>}
          {response.data && (
            <>
              <h3>
                {response.data.length} results for "{query}"
              </h3>
              {response.data.map((result) => {
                let path = result.url.replace(
                  "https://controlroom.jurassicoutpost.com",
                  ""
                );
                path = path.replace("https://jurassicoutpost.com", "");
                return (
                  <Suggest key={result.id}>
                    <SuggestTitle to={path}>
                      <h5>{ReactHtmlParser(result.title)}</h5>
                    </SuggestTitle>
                  </Suggest>
                );
              })}
            </>
          )}
        </Suggests>
      )}
    </Container>
  );
};

export default SearchComponent;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Input = styled.input`
  background: ${(props) => props.theme.color.darkFour};
  outline: none;
  border: 0;
  padding: 0 10px;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 16px;

  height: 40px;
  width: 100%;
`;

const Suggests = styled.ul`
  position: absolute;
  background: ${(props) => props.theme.color.lightGrey};
  color: black;
  z-index: 99999;
  left: 0;
  top: 45px;
  margin: 0;
  text-align: left;
  padding: 20px;
  list-style: none;
  max-width: 768px;
  max-height: 500px;
  overflow: scroll;
`;

const Suggest = styled.li`
  padding-top: 10px;
`;

const SuggestTitle = styled(Link)`
  color: ${(props) => props.theme.color.darkFour};
  margin-bottom: 20px;

  &:hover {
    color: ${(props) => props.theme.color.accent};
  }
`;
