import { ITheme } from "./theme-definition";

export const mainTheme: ITheme = {
  name: "outpost",
  color: {
    white: "#ffffff",
    primaryLight: "#E2E9F3",
    primaryDark: "#3D70B2",
    accentLight: "#D9D9D9",
    darkOne: "#333333",
    darkTwo: "#262626",
    darkThree: "#1e1e1e",
    darkFour: "#171717",
    accentDark: "#672900",
    accent: "#ff4e00",
    lightGrey: "#e5e5e5",
    midGrey: "#c1c1c1",
    error: "red",
    redThemeAccent: "#0077ac",
    orangeThemeAccent: "#ff4e00",
    blueThemeAccent: "#d02027",
  },
  description: "Jurassic Outpost Theme 2019",
  font: {
    family: {
      bold: '"Roboto", Arial, Helvetica, sans-serif',
      heavy: '"Roboto", Arial, Helvetica, sans-serif',
      light: '"Roboto", Arial, Helvetica, sans-serif',
      regular: '"Roboto", Arial, Helvetica, sans-serif',
    },
    size: {
      display_5: "6rem",
      display_4: "5.6rem",
      display_3: "4.8rem",
      display_2: "4.2rem",
      display_1: "3.6rem",
      title: "3.0rem",
      subhead_3: "2.8rem",
      subhead_2: "2.4rem",
      subhead_1: "2.1rem",
      body_2: "1.8rem",
      body_1: "1.6rem",
      small_3: "1.5rem",
      small_2: "1.4rem",
      small_1: "1.3rem",
    },
    lineHeight: {
      default: "1.4em",
      normalized: "1em",
      small: "1.2em",
      large: "1.6em",
    },
    spacing: {
      default: "normal",
      large: "normal",
      medium: "0.2em",
      small: "normal",
    },
    weight: {
      bold: 700,
      heavy: 900,
      light: 100,
      regular: 500,
    },
    decoration: {
      underlineLight: "underline solid #ffa76c",
      underlineNormal: "underline solid #f05a1f",
    },
  },
  owner: "Local Theme",
  radius: {
    bottomLeft: "1.25em 1.25em 1.25em 0.0em",
    bottomRight: "1.25em 1.25em 0.0em 1.25em",
    radius: "1.25em",
    topLeft: "0.0em 1.25em 1.25em 1.25em",
    topRight: "1.25em 0.0em 1.25em 1.25em",
  },
  sizes: {
    spacing: {
      l: "3rem",
      m: "2rem",
      ml: "2.5rem",
      ms: "1.5rem",
      s: "1rem",
      xl: "4rem",
      xs: "0.5rem",
      xxl: "6rem",
      xxs: "0.25rem",
    },
    thickness: {
      l: "3rem",
      m: "2rem",
      ml: "2.5rem",
      ms: "1.5rem",
      s: "1rem",
      xl: "4rem",
      xs: "0.5rem",
      xxl: "6rem",
      xxs: "0.25rem",
    },
  },
  type: "theme",
  version: "2.0",
  carousel: {
    icons: {
      color: "#f05a1f",
    },
  },
  global: {
    colors: {
      focus: "#ff4e00",
      selected: "#ff4e00",
      brand: "#ff4e00"
    },
    font: {
      family: "Roboto",
      size: "18px",
      height: "20px",
    },
  },
};

export const blueTheme: ITheme = {
  ...mainTheme,
  name: "world",
  carousel: {
    icons: {
      color: "#37c1f0",
    },
  },
  global: {
    colors: {
      focus: "#37c1f0",
      selected: "#37c1f0",
      brand: "#37c1f0"
    }
  },
  color: {
    ...mainTheme.color,
    accentLight: "#37c1f0",
    accentDark: "#00547a",
    accent: "#0077ac",
  },
};

export const redTheme: ITheme = {
  ...mainTheme,
  name: "park",
  global: {
    colors: {
      focus: "#ef000b",
      selected: "#ef000b",
      brand: "#ef000b"
    }
  },
  carousel: {
    icons: {
      color: "#ef000b",
    },
  },
  color: {
    ...mainTheme.color,
    accentLight: "#ef000b",
    accentDark: "#7c0003",
    accent: "#d02027",
  },
};

export type FontWeights = typeof mainTheme.font.weight;
export type FontSizes = typeof mainTheme.font.size;
export type Spacing = typeof mainTheme.sizes.spacing;
export type Colors = typeof mainTheme.color;

export type ThemeType = typeof mainTheme;
