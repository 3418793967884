import React, { ReactChild } from "react";
import Helmet from "react-helmet";
import { mainTheme as JurassicOutpostTheme } from "../lib/theme";
import { themeProvider } from "../lib/theme-provider";
import { basicStyles } from "../lib/basic-styles";
import Confines from "./Confines";
import Footer from "./Footer";
import Header from "./Header";
import "./layout.css";
import Navigation from "./Navigation";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { PageProps } from "gatsby";
import MyAdSense from "./Adsense";
import { StickyContainer } from "react-sticky";
import { CookiesProvider } from "react-cookie";
import { useCookies } from "react-cookie";
import { Grommet } from "grommet";
import { createGlobalStyle } from "styled-components";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab, fas);
const GlobalStyle = createGlobalStyle`
  ${basicStyles}
`;

interface IProps {
  children: ReactChild;
  location: PageProps["location"];
}

const DinoBackground = styled.div`
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`;

const Layout = (props: IProps) => {
  const [theme, setTheme] = React.useState(JurassicOutpostTheme);
  const [cookies, setCookie] = useCookies(["theme"]);

  const onThemeChange = (theme: any) => {
    setCookie("theme", theme, { path: "/" });
    setTheme(theme);
  };
  const ThemeProvider = themeProvider(
    () => (
      <React.Fragment>
        <GlobalStyle />
        <CookiesProvider>
          <Grommet theme={theme}>
            <Helmet />
            <Header />
            <Navigation />
            <DinoSkellies />
            <Confines>
              <main>
                <StickyContainer>
                  {props.children}
                  <MyAdSense location={props.location} />
                </StickyContainer>
              </main>
            </Confines>
            <Footer setTheme={onThemeChange} />
          </Grommet>
        </CookiesProvider>
      </React.Fragment>
    ),
    "normal",
    cookies.theme || theme
  );

  return <ThemeProvider />;
};

const DinoSkellies = () => (
  <DinoBackground>
    <StaticImage
      alt="background"
      layout="fullWidth"
      quality={100}
      src="../images/background-skellies.jpg"
    />
  </DinoBackground>
);

export default Layout;
