import * as React from "react";
import { ThemeProvider } from "styled-components";
import { asyncTheme } from "./async-theme";
import { ITheme } from "./theme-definition";

interface IProvidedAppProps {
  theme: ITheme;
}

export function themeProvider<T>(
  ThemedComponent: React.FunctionComponent<{}> | React.ComponentClass<{}, {}>,
  mode: string,
  themeUrl: string | ITheme
) {
  const ProvidedApp = (props: IProvidedAppProps) => {
    const { theme } = props;

    return (
      <ThemeProvider theme={{ ...theme, mode }}>
        <ThemedComponent />
      </ThemeProvider>
    );
  };

  const ComponentWithTheme = asyncTheme(ProvidedApp, themeUrl);

  return () => <ComponentWithTheme />;
}
