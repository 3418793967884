import React, { HTMLAttributes } from "react";
import styled from "../lib/styled";
import icons, { Icons } from "../lib/icons";
import { SvgProperties } from "csstype";
import { IThemeColour } from "../lib/theme-definition";

const IconWrapper = styled.svg<{
  hoverColor: keyof IThemeColour;
  fillColor: keyof IThemeColour;
}>`
  overflow: visible;
  transition: 0.2s ease;
  fill: ${({ theme, fillColor }) => theme.color[fillColor]};
  :hover {
    cursor: pointer;

    fill: ${({ theme, hoverColor }) => theme.color[hoverColor]};
  }
`;

const Icon: React.SFC<IIconWrapperProps> = ({
  icon,
  size,
  fillColor,
  hoverColor,
  onClick,
  viewBox,
  ...rest
}) => {
  const iconPath = icons[icon];
  return (
    <IconWrapper
      {...rest}
      onClick={onClick}
      fillColor={fillColor || "accentDark"}
      hoverColor={hoverColor || fillColor || "accentDark"}
      width={size || 25}
      height={size || 25}
      viewBox={viewBox || "0 0 25 25"}
    >
      <path d={iconPath} />
    </IconWrapper>
  );
};

interface IIconWrapperProps extends HTMLAttributes<SvgProperties> {
  icon: keyof Icons;
  size?: number;
  fillColor?: keyof IThemeColour;
  hoverColor?: keyof IThemeColour;
  viewBox?: string;
  onClick?: () => void;
}

export default Icon;
