import { css } from "styled-components";
import { mainTheme } from "./theme";

const basicStyles = css`
  figure.alignleft {
    margin: 0;
  }

  .wp-block-image {
    display: inline-block;
    padding: 10px;
  }

  .wp-block-image img {
    margin: 0;
  }

  .wp-block-image a {
    margin: 0;
  }
  body {
    margin: 0;
    padding: 0;
    font-size: 100%;
    line-height: 1.5;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    background-color: ${mainTheme.color.darkThree};
  }

  iframe {
    margin: 0;
  }
  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  nav,
  section {
    display: block;
  }
  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  p,
  input {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }
  h1,
  h2,
  h3,
  h4 {
    margin: 0.5rem 0 0.5rem;
    line-height: 1.25;
    font-weight: bold;
  }
  h1 {
    font-size: 2.25rem;
    line-height: 1.1;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.2em;
  }
  h4 {
    font-size: 1em;
  }
  ul,
  ol {
    margin: 1em 0;
  }
  p {
    line-height: 1.3rem;
    margin: 1em 0;
  }
  blockquote {
    margin: 1em 40px;
  }
  figure {
    margin: 1em 0;
  }

  a {
    text-decoration: none;
  }
  a:link {
    /* color: #0000ee; */
  }
  a:visited {
    /* color: #551a8b; */
  }
  a:active {
    /* color: #ee0000; */
  }

  a:hover,
  a:focus {
  }

  a:active,
  a:hover {
    outline: none;
  }

  a img {
    border: none;
  }

  q,
  cite {
    font-style: italic;
  }

  q:before,
  q:after {
    content: "";
  }

  sup,
  sub {
    line-height: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }
  input,
  button,
  select {
    vertical-align: middle;
  }

  table {
    border-collapse: collapse;
  }
  td,
  th {
    padding: 1px;
    vertical-align: top;
    text-align: left;
  }
  td:first-child,
  th:first-child {
    empty-cells: hide;
  }

  p.has-small-font-size {
    font-size: 14px;
  }

  .hide {
    display: none;
  }
  .wp-block-columns {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
    margin-bottom: 15px;
  }

  /* one item */
  .wp-block-column:first-child:nth-last-child(1) {
    /* -or- .wp-block-column:only-child { */
    width: 100%;
  }

  /* two items */
  .wp-block-column:first-child:nth-last-child(2),
  .wp-block-column:first-child:nth-last-child(2) ~ .wp-block-column {
    width: 50%;
  }

  /* three items */
  .wp-block-column:first-child:nth-last-child(3),
  .wp-block-column:first-child:nth-last-child(3) ~ .wp-block-column {
    width: 33.3333%;
  }

  /* four items */
  .wp-block-column:first-child:nth-last-child(4),
  .wp-block-column:first-child:nth-last-child(4) ~ .wp-block-column {
    width: 25%;
  }
`;
export { basicStyles };
