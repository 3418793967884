import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import { Row } from "hedron";
import * as React from "react";
import { presets } from "../lib/presets";
import styled from "../lib/styled";
import { IJurassicOutpostSettings } from "../types/settings";
import Countdown from "./Countdown";
import { IFixedImageSize } from "../types/imageSizes";

interface ISettings {
  jurassicOutpostSettings: Pick<
    IJurassicOutpostSettings,
    | "siteSidebarCountdownBoxImage"
    | "siteSidebarCountdownDate"
    | "siteSidebarBoxes1Image"
    | "siteSidebarBoxes1Link"
    | "siteSidebarBoxes2Image"
    | "siteSidebarBoxes2Link"
    | "siteSidebarBoxes3Image"
    | "siteSidebarBoxes3Link"
    | "siteSidebarBoxes4Image"
    | "siteSidebarBoxes4Link"
    | "siteSidebarBoxes5Image"
    | "siteSidebarBoxes5Link"
    | "siteSidebarBoxes6Image"
    | "siteSidebarBoxes6Link"
    | "siteSidebarBoxes7Image"
    | "siteSidebarBoxes7Link"
    | "siteSidebarBoxes8Image"
    | "siteSidebarBoxes8Link"
    | "siteSidebarBoxes9Image"
    | "siteSidebarBoxes9Link"
    | "siteSidebarBoxes10Image"
    | "siteSidebarBoxes10Link"
  >;
}

interface IData {
  allWp: {
    nodes: ISettings[];
  };
}

interface IProps {
  Sharing?: JSX.Element;
  data: IData;
}

const SideBar = (props: IProps) => {
  const data = props.data;
  const options = data.allWp.nodes[0].jurassicOutpostSettings;

  const boxOptions = [
    "siteSidebarBoxes1",
    "siteSidebarBoxes2",
    "siteSidebarBoxes3",
    "siteSidebarBoxes4",
    "siteSidebarBoxes5",
    "siteSidebarBoxes6",
    "siteSidebarBoxes7",
    "siteSidebarBoxes8",
    "siteSidebarBoxes9",
    "siteSidebarBoxes10",
  ];

  const {
    siteSidebarCountdownBoxImage,
    siteSidebarCountdownDate,
    ...boxes
  } = options;
  const countdownImage = siteSidebarCountdownBoxImage
    ? siteSidebarCountdownBoxImage
    : null;
  return (
    <Wrapper>
      {props.Sharing}
      {boxes &&
        boxOptions.map((opt, i) => {
          const boxImage: IFixedImageSize = boxes[`${opt}Image`];
          const boxLink: string = boxes[`${opt}Link`];
          if (!boxImage || !boxLink) {
            return null;
          }
          return (
            <SideBarBox key={i} href={boxLink}>
              {boxImage && (
                <SideBarBackgroundImage
                  image={boxImage.childImageSharp.gatsbyImageData}
                  alt="Sidebar box"
                  style={{
                    zIndex: 0,
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            </SideBarBox>
          );
        })}
    </Wrapper>
  );
};

interface WrapperProps {
  Sharing?: JSX.Element;
}
export default (props: WrapperProps) => (
  <StaticQuery
    query={graphql`
      query sideBar {
        allWp {
          nodes {
            id
            internal {
              type
            }
            jurassicOutpostSettings {
              siteSidebarCountdownDate
              siteSidebarCountdownBoxImage {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 260, height: 260)
                }
              }

              siteSidebarBoxes1Link
              siteSidebarBoxes1Image {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 260, height: 260)
                }
              }

              siteSidebarBoxes2Link
              siteSidebarBoxes2Image {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 260, height: 260)
                }
              }

              siteSidebarBoxes3Link
              siteSidebarBoxes3Image {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 260, height: 260)
                }
              }

              siteSidebarBoxes4Link
              siteSidebarBoxes4Image {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 260, height: 260)
                }
              }

              siteSidebarBoxes5Link
              siteSidebarBoxes5Image {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 260, height: 260)
                }
              }

              siteSidebarBoxes6Link
              siteSidebarBoxes6Image {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 260, height: 260)
                }
              }

              siteSidebarBoxes7Link
              siteSidebarBoxes7Image {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 260, height: 260)
                }
              }

              siteSidebarBoxes8Link
              siteSidebarBoxes8Image {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 260, height: 260)
                }
              }

              siteSidebarBoxes9Link
              siteSidebarBoxes9Image {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 260, height: 260)
                }
              }

              siteSidebarBoxes10Link
              siteSidebarBoxes10Image {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 260, height: 260)
                }
              }

            }
          }
        }
      }
    `}
    render={(data) => (
      <SideBar data={data} Sharing={props.Sharing} {...props} />
    )}
  />
);

const Wrapper = styled(Row)`
  ${presets.mq.max.tablet} {
    justify-content: space-between;
  }
`;

const SideBarBox = styled.a`
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
  ${presets.mq.max.tablet} {
    width: calc(50% - 10px);
    padding-bottom: 50%;
    &:last-child {
      margin-bottom: 15px;
    }
  }
  ${presets.mq.max.mobile} {
    width: 100%;
    padding-bottom: 100%;
    &:last-child {
      margin-bottom: 15px;
    }
  }
  position: relative;
  display: block;
  max-width: 100%;
  padding-bottom: 100%;
  margin-bottom: 15px;
  border: 1px solid ${(props) => props.theme.color.accent};
`;

const SideBarBackgroundImage = styled(GatsbyImage)``;
