import * as React from "react";
import styled from "../lib/styled";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";

const Wrapper = styled.div`
  background: ${props => props.theme.color.darkThree};
  color: white;
  display: flex;
  margin-left: auto;
`;

const IconLink = styled.a`
  padding: 10px;
  padding-bottom: 6px;
  color: white;
  border-bottom: 2px solid transparent;
  opacity: 0.5;
  &:hover,&:focus  {
    opacity: 1;
    border-bottom: 2px solid ${props => props.theme.color.accent};
  }
`;

const SocialIcons = ({
  facebook,
  twitter,
  instagram,
  youtube
}: {
  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
}) => {
  return (
    <Wrapper>
      <IconLink href={facebook} target="_blank" tabindex="0" aria-label="Visit our facebook" rel="noopener noreferrer">
        <FaFacebookF />
      </IconLink>
      <IconLink href={twitter} target="_blank" tabindex="0" rel="noopener noreferrer" aria-label="Visit our Twitter">
        <FaTwitter />
      </IconLink>
      <IconLink href={instagram} target="_blank" tabindex="0" rel="noopener noreferrer" aria-label="Visit our Instagram">
        <FaInstagram />
      </IconLink>
      <IconLink href={youtube} target="_blank" tabindex="0" rel="noopener noreferrer" aria-label="Visit our YouTube">
        <FaYoutube />
      </IconLink>
    </Wrapper>
  );
};

export default SocialIcons;
