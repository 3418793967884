import * as React from "react";
import styled from "../lib/styled";
import Confines from "./Confines";
import SocialIcons from "./SocialIcons";
import { graphql, StaticQuery, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import { IFixedImageSize } from "../types/imageSizes";
import Icon from "./Icon";
import { blueTheme, redTheme, mainTheme } from "../lib/theme";
import { useCookies } from "react-cookie";
import { presets } from "../lib/presets";
import ReactHtmlParser from "react-html-parser";
import { IJurassicOutpostSettings } from "../types/settings";

interface ISettings {
  jurassicOutpostSettings: Pick<
    IJurassicOutpostSettings,
    | "siteLogo"
    | "footerText"
    | "socialFacebookLink"
    | "socialTwitterLink"
    | "socialInstagramLink"
    | "socialYouTubeLink"
  >;
}

interface IData {
  allWp: {
    nodes: ISettings[];
  };
}

interface IProps {
  setTheme: (theme: any) => void;
  data: IData;
}

const Footer = ({ data, setTheme }: IProps) => {
  const [cookies, setCookie] = useCookies(["theme"]);
  return (
    <footer>
      <Confines>
        <FooterWrapper>
          <LogoThemeButtons>
            <LogoImage
              image={
                data.allWp.nodes[0].jurassicOutpostSettings.siteLogo
                  .childImageSharp.gatsbyImageData
              }
              alt="Logo"
              className="logo"
            />
            <ThemeButtons>
              <Icon
                onClick={() => setTheme(mainTheme)}
                fillColor="orangeThemeAccent"
                viewBox="0 0 65 65"
                icon={
                  cookies.theme
                    ? cookies.theme.name !== "park" &&
                      cookies.theme.name !== "world"
                      ? "themeOutpostSelected"
                      : "themeOutpost"
                    : "themeOutpostSelected"
                }
              />
              <Icon
                onClick={() => setTheme(redTheme)}
                fillColor="blueThemeAccent"
                viewBox="0 0 65 65"
                icon={
                  cookies.theme && cookies.theme.name === "park"
                    ? "themeParkSelected"
                    : "themePark"
                }
              />
              <Icon
                onClick={() => setTheme(blueTheme)}
                fillColor="redThemeAccent"
                viewBox="0 0 65 65"
                icon={
                  cookies.theme && cookies.theme.name === "world"
                    ? "themeWorldSelected"
                    : "themeWorld"
                }
              />
            </ThemeButtons>
          </LogoThemeButtons>
          <CopyrightText>
            {ReactHtmlParser(
              data.allWp.nodes[0].jurassicOutpostSettings.footerText
            )}
          </CopyrightText>
          <FooterLink to="/about">
            <FooterIcon>
              <Icon
                style={{
                  paddingLeft: "2px",
                  paddingTop: "2px",
                }}
                icon="dna"
                fillColor="white"
              />
            </FooterIcon>
            <FooterText>About</FooterText>
          </FooterLink>
          <FooterLink to="/contact">
            <FooterIcon>
              <Icon
                style={{ paddingTop: "7px" }}
                icon="mail"
                fillColor="white"
              />
            </FooterIcon>
            <FooterText>Contact</FooterText>
          </FooterLink>
          <SocialIcons
            facebook={
              data.allWp.nodes[0].jurassicOutpostSettings.socialFacebookLink
            }
            twitter={
              data.allWp.nodes[0].jurassicOutpostSettings.socialTwitterLink
            }
            instagram={
              data.allWp.nodes[0].jurassicOutpostSettings.socialInstagramLink
            }
            youtube={
              data.allWp.nodes[0].jurassicOutpostSettings.socialYouTubeLink
            }
          />
        </FooterWrapper>
      </Confines>
      <ins
        className="CANBMDDisplayAD"
        data-bmd-ad-unit="813128420211025T1539443605FA658AC398E410DA4B0E60B4950A7AF"
        style={{ display: "block" }}
      >
      </ins>
      <script src="https://secureaddisplay.com/au/bmd/"></script>

    </footer>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query footerQuery {
        allWp {
          nodes {
            jurassicOutpostSettings {
              footerText
              siteLogo {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 30)
                }
              }
              socialTwitterLink
              socialFacebookLink
              socialInstagramLink
              socialYouTubeLink
            }
          }
        }
      }
    `}
    render={(data) => <Footer data={data} {...props} />}
  />
);

const FooterWrapper = styled.div`
  background: ${(props) => props.theme.color.darkFour};
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin: 20px;
  ${presets.mq.max.phablet} {
    padding: 20px 0;
    margin: 20px 0 0 0;
  }
  justify-content: center;
  align-items: center;

  ${presets.mq.max.tablet} {
    flex-direction: column;
    text-align: center;

    > div {
      margin-left: 0;
      margin-top: 5px;
    }
  }
`;

const CopyrightText = styled.p`
  color: white;
  font-size: 10px;
  line-height: 1.2em;
  margin: 0 20px;

  ${presets.mq.max.tablet} {
    padding-bottom: 25px;
  }
`;

const FooterIcon = styled.div`
  position: relative;
  padding: 8px;
  background: ${(props) => props.theme.color.darkThree};
  border: 1px solid ${(props) => props.theme.color.darkFour};

  &:before {
    content: "";
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 1px solid ${(props) => props.theme.color.darkFour};
    position: absolute;
    top: 1px;
    left: 1px;
  }
`;
const FooterText = styled.div`
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.color.darkThree} 0%,
    ${(props) => props.theme.color.darkTwo} 100%
  );
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 45px;
`;

const FooterLink = styled(Link)`
  color: white;
  display: flex;
  justify-content: center;
  margin: 0 10px;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Keep Calm";

  &:hover ${FooterIcon} {
    border-bottom: 1px solid ${(props) => props.theme.color.accent};
  }
  ${presets.mq.max.tablet} {
    padding: 5px;
  }
`;

const LogoImage = styled(GatsbyImage)`
  width: 150px;
  height: 25px;
`;

const LogoThemeButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${presets.mq.max.tablet} {
    padding-bottom: 10px;
  }
`;
const ThemeButtons = styled.div`
  svg {
    margin: 5px;
    margin-bottom: 0;
    cursor: pointer;
  }
`;
