import * as React from "react";
import styled from "../lib/styled";
import { presets } from "../lib/presets";

const Wrapper = styled.div`
  max-width: 1200px;
  ${presets.mq.max.xl} {
    max-width: 1000px;
  }
  ${presets.mq.max.desktop} {
    max-width: 960px;
  }
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
`;

interface IConfinesProps {
  children: React.ReactChild;
}

const Confines: React.SFC<IConfinesProps> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

export default Confines;
