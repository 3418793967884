import * as React from "react";
import { Link, StaticQuery } from "gatsby";
import Confines from "./Confines";
import styled from "../lib/styled";
import { graphql } from "gatsby";
import ReactResizeDetector from "react-resize-detector";
import { presets } from "../lib/presets";
import * as _ from "lodash";

interface IMenuItem {
  url: string;
  label: string;
  parentId: string | null;
  id: string;
  children?: IMenuItem[];
}

interface IData {
  data: {
    allWpMenuItem: {
      nodes: IMenuItem[];
    };
  };
}

const flatListToHierarchical = (data: IMenuItem[] = []): IMenuItem[] => {
  const tree = [];
  const childrenOf = {};
  data.forEach((item) => {
    const newItem = { ...item };
    const { id, parentId = 0 } = newItem;
    childrenOf[id] = childrenOf[id] || [];
    newItem["children"] = childrenOf[id];
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem);
  });
  return tree;
};

const Navigation = ({ data }: IData) => {
  const [showNav, setShowNav] = React.useState(true);

  const onResize = (width: number) => {
    if (width < presets.mq.values.tablet) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  };

  const navigationLinks = flatListToHierarchical(data.allWpMenuItem.nodes);

  return (
    <NavigationWrapper>
      <ReactResizeDetector handleWidth onResize={(w) => onResize(w)} />
      <Confines>
        <NavContainer>
          <BurgerMenu
            className={showNav ? "open" : "close"}
            onClick={() => setShowNav(!showNav)}
          >
            {showNav ? "Close Menu" : "Menu"}
          </BurgerMenu>
          {showNav && (
            <NavLinkContainer>
              {navigationLinks.map((navItem, i) => {
                if (navItem.children && navItem.children.length > 0) {
                  return <DropDownWrapper key={i} navItem={navItem} />;
                }
                if (navItem.url) {
                  if (/^(f|ht)tps?:\/\//i.test(navItem.url)) {
                    if (
                      !navItem.url.includes(
                        "controlroom.jurassicoutpost.com/"
                      ) &&
                      !navItem.url.includes("jurassicoutpostdev.com/")
                    ) {
                      return (
                        <a key={i} href={navItem.url} target="_blank">
                          {navItem.label}
                        </a>
                      );
                    }
                  }
                  let link = navItem.url;
                  if (
                    navItem.url.includes("controlroom.jurassicoutpost.com/")
                  ) {
                    link = navItem.url.split(
                      "controlroom.jurassicoutpost.com"
                    )[1];
                  }
                  if (navItem.url.includes("jurassicoutpostdev.com")) {
                    link = navItem.url.split("jurassicoutpostdev.com")[1];
                  }

                  return (
                    <Link key={i} activeClassName="active" to={link}>
                      {navItem.label}
                    </Link>
                  );
                }
                return null;
              })}
            </NavLinkContainer>
          )}
        </NavContainer>
      </Confines>
    </NavigationWrapper>
  );
};

const DropDownWrapper: React.FC<{ navItem: IMenuItem }> = ({ navItem }) => {
  const [showFilmsDropdown, setFilmsDropdown] = React.useState(false);
  return (
    <DropdownContainer>
      <a onClick={() => setFilmsDropdown(!showFilmsDropdown)}>
        {navItem.label}
      </a>
      {showFilmsDropdown && (
        <Dropdown>
          {navItem.children &&
            _.map(navItem.children, (dropDownItem, x) => {
              let classes = "";

              if (dropDownItem.url.includes("disabled")) {
                classes = "disabled";
              }
              if (dropDownItem.url.includes("separator")) {
                return <span key={x} className="separator" />;
              }
              if (dropDownItem.url) {
                /**
                 * If film link is external, then open in new tab
                 */
                if (/^(f|ht)tps?:\/\//i.test(dropDownItem.url)) {
                  if (
                    !dropDownItem.url.includes(
                      "controlroom.jurassicoutpost.com/"
                    ) &&
                    !dropDownItem.url.includes("jurassicoutpostdev.com/")
                  ) {
                    return (
                      <a
                        key={x}
                        className={classes}
                        href={dropDownItem.url}
                        target="_blank"
                      >
                        {dropDownItem.label}
                      </a>
                    );
                  }
                }
                /**
                 * Otherwise use Link component
                 */
                let link = dropDownItem.url;
                if (
                  dropDownItem.url.includes("controlroom.jurassicoutpost.com/")
                ) {
                  link = dropDownItem.url.split(
                    "controlroom.jurassicoutpost.com"
                  )[1];
                }
                if (dropDownItem.url.includes("jurassicoutpostdev.com")) {
                  link = dropDownItem.url.split("jurassicoutpostdev.com")[1];
                }

                return (
                  <Link
                    key={x}
                    activeClassName="active"
                    className={classes}
                    to={link}
                  >
                    {dropDownItem.label}
                  </Link>
                );
              }
            })}
        </Dropdown>
      )}
    </DropdownContainer>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query NavigationQuery {
        allWpMenuItem {
          nodes {
            url
            label
            parentId
            id
          }
        }
      }
    `}
    render={(data) => <Navigation data={data} {...props} />}
  />
);

const NavigationWrapper = styled.nav`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.color.darkOne};
`;

const BurgerMenu = styled.div`
  cursor: pointer;
  color: white;
  font-family: "Keep Calm";
  letter-spacing: 4px;
  text-transform: uppercase;
  padding: 8px 30px;
  font-size: 11px;

  &.open {
    opacity: 0.5;
  }

  ${presets.mq.min.tablet} {
    display: none;
  }
`;
const NavContainer = styled.div``;
const NavLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 20px;

  a {
    cursor: pointer;
    color: white;
    font-family: "Keep Calm";
    letter-spacing: 4px;
    text-transform: uppercase;
    padding: 8px 30px;
    padding-left: 32px;

    ${presets.mq.max.xl} {
      padding: 8px 25px;
      display: block;
    }
    ${presets.mq.max.desktop} {
      padding: 8px 18px;
      display: block;
    }
    ${presets.mq.max.smallDesktop} {
      padding: 8px;
      letter-spacing: 3px;
      display: block;
    }
    font-size: 11px;
    &.active,
    &:hover {
      background: ${(props) => props.theme.color.accentDark};
    }
  }
  ${presets.mq.max.tablet} {
    flex-direction: column;
    margin: 0;
    a {
      padding: 12px 30px;
      display: block;
      width: 100%;
    }
  }
`;
const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  ${presets.mq.max.tablet} {
    flex-direction: column;
  }
`;
const Dropdown = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 240px;
  top: 100%;
  z-index: 9;
  display: flex;
  padding: 5px 0;
  flex-direction: column;
  background: ${(props) => props.theme.color.darkTwo};

  .separator {
    position: relative;
    margin-bottom: 20px;
    &:after {
      content: "";
      position: absolute;
      width: 30px;
      height: 1px;
      background: ${(props) => props.theme.color.accent};
      bottom: -10px;
      left: 30px;
    }
  }
  a {
    font-size: 10px;
    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }

  ${presets.mq.max.tablet} {
    position: relative;
    width: 100%;
  }
`;
