import * as React from "react";
import styled, { css } from "../../lib/styled";
import { FaSearch } from "react-icons/fa";
import SearchComponent from "../SearchComponent";
import { presets } from "../../lib/presets";

const Wrapper = styled.div`
  background: ${props => props.theme.color.darkFour};
  color: white;
  display: flex;

  ${presets.mq.max.phablet} {
    flex-direction: row-reverse;
  }
`;

const SearchButton = styled.button`
  padding: 10px;
  padding-bottom: 6px;
  outline: none;
  border: 0;
  color: ${props => props.theme.color.darkOne};
  background: ${props => props.theme.color.darkThree};

  &:hover {
    color: white;
    cursor: pointer;
  }
`;

const SiteSearch = () => {
  return (
    <Wrapper>
      <SearchComponent minCharacters={4} />
      <SearchButton aria-label="Search the site">
        <FaSearch />
      </SearchButton>
    </Wrapper>
  );
};

export default SiteSearch;
