export const presets = {
  mq: {
    min: {
      mobile: `@media (min-width: 480px)`,
      phablet: `@media (min-width: 600px)`,
      tablet: `@media (min-width: 768px)`,
      smallDesktop: `@media (min-width: 950px)`,
      desktop: `@media (min-width: 1100px)`,
      xl: `@media (min-width: 1200px)`,
      xxl: `@media (min-width: 1600px)`
    },
    max: {
      mobile: `@media (max-width: 480px)`,
      phablet: `@media (max-width: 600px)`,
      tablet: `@media (max-width: 768px)`,
      smallDesktop: `@media (max-width: 950px)`,
      desktop: `@media (max-width: 1100px)`,
      xl: `@media (max-width: 1200px)`,
      xxl: `@media (max-width: 1600px)`
    },
    values: {
      mobile: 480,
      phablet: 600,
      tablet: 768,
      smallDesktop: 950,
      desktop: 1100,
      xl: 1200,
      xxl: 1600
    }
  },
  animation: {
    curveDefault: `cubic-bezier(0.4, 0, 0.2, 1)`,
    curveExpo: `cubic-bezier(.17, .67, .83, .67)`,
    speedDefault: `200ms`,
    speedFast: `100ms`,
    speedSlow: `350ms`
  }
};
export type MediaQueries = typeof presets.mq;
export type Animations = typeof presets.animation;
