import * as React from "react";
import styled from "../lib/styled";

const Wrapper = styled.article`
  position: relative;
  background: ${(props) => props.theme.color.lightGrey};
  padding: 25px;
  height: 100%;
  flex-grow: 1;

  * {
    max-width: 100%;
  }

  a {
    color: ${(props) => props.theme.color.accent};
  }
`;
const PageContent = ({ children }: { children: JSX.Element }) => (
  <Wrapper>{children}</Wrapper>
);

export default PageContent;
